
<template>
  <div class="iss-wrapper">
    自动回复
 </div>
</template>

<script>
export default {
 setup () {
   

   return {}
 }
}
</script>

<style lang="less" scoped>

</style>